a {
  font-family: monospace;
  outline: none;
  transition: 0.6s;
  text-align: center;
  display: block;
}

img {
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  object-fit: fill;
  object-position: center;
  cursor: pointer;
}

a:link {
  color: #000000;
}

a:visited {
  color: #000000;
}

a:focus {
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

a:active {
}
